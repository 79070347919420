
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/cn/Safehouse",
      function () {
        return require("private-next-pages/cn/Safehouse.js");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/cn/Safehouse"])
      });
    }
  